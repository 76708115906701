import React, { useEffect, useState } from "react";
import Axios from "axios";
import { useHistory } from "react-router-dom";
import "./cmath.css";
import logo from "./logo.png";
import statistics from "./statistics.png";
import xicon from "./x.png";

const route = "/api/academy";
const route2 = "/api/cmath";

function Cmath() {

  const [academy_List, Set_academy_List] = useState([]);
  const [count_List, Set_count_List] = useState([]);

  const history = useHistory();

  useEffect(() => {
    document.getElementById("root").removeAttribute("style");
    document.getElementById("root").children[0].removeAttribute("style");
    Axios.post("/api/damo").then((res) => {
        console.log(res.data)
      if(!res.data.success) {
        alert("잘못된 접근입니다.");
        history.goBack();
      }
      else {
        Axios.post(`${route}/all_Load`, {}).then((res) => {
          Set_academy_List(res.data);
          Axios.post(`${route2}/cmath_Load`, {}).then((res) => {
            Set_count_List(res.data.reverse());
            document.getElementById("master_View").style.display = "block";
          });
        });
      }
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function usage(id, use, type) {
    if (window.confirm("변경하시겠습니까?")) {
      Axios.post(`${route}/academy_Modify`, {
        id: id,
        use: use,
        type: type
      }).then((res) => Set_academy_List(res.data));
    }
  }

  function counting(what) {
    let sum = 0;
    count_List.map((value) => {
      return (sum += Number(value[what]));
    });
    return sum;
  }

  function count_Div() {
    return (
      <table
        id="count_Div"
        style={{
          display: "none",
          position: "fixed",
          top: "100px",
          right: "100px",
          padding: "78px 23px 30px",
          width: "max-content",
          height: "600px",
          maxHeight: "80vh",
          overflow: "scroll",
          backgroundColor: "white",
          boxShadow: "2px 2px 7px rgba(0,0,0,0.2)",
          zIndex: "999",
        }}
      >
        <caption
          style={{
            position: "absolute",
            right: "8%",
            top: 0,
          }}
        >
          <img
            src={xicon}
            alt="닫기"
            style={{
              position: "fixed",
              cursor: "pointer",
            }}
            rowSpan={5}
            onClick={() =>
              (document.getElementById("count_Div").style.display = "none")
            }
          />
        </caption>
        <thead>
          <tr
            style={{
              display: "flex",
              width: "max-content",
              height: "40px",
              backgroundColor: "#627A86",
            }}
          >
            <td className="cmath_Cell_Head" style={{ width: "120px" }}>
              날짜
            </td>
            <td className="cmath_Cell_Head" style={{ width: "120px" }}>
              사이트 접근
            </td>
            <td className="cmath_Cell_Head" style={{ width: "120px" }}>
              상세 내용
            </td>
            <td className="cmath_Cell_Head" style={{ width: "120px" }}>
              접수 신청
            </td>
            <td className="cmath_Cell_Head" style={{ width: "120px" }}>
              접수 완료
            </td>
          </tr>
        </thead>
        <tbody>
          {count_List.map((value, index) => {
            return (
              <tr
                key={index}
                style={{
                  display: "flex",
                  width: "max-content",
                  height: "50px",
                  backgroundColor: index % 2 === 0 ? "#fff" : "#E5E5E5",
                }}
              >
                <td className="cmath_Cell_Body" style={{ width: "120px" }}>
                  {value.day}
                </td>
                <td className="cmath_Cell_Body" style={{ width: "120px" }}>
                  {value.access}
                </td>
                <td className="cmath_Cell_Body" style={{ width: "120px" }}>
                  {value.content}
                </td>
                <td className="cmath_Cell_Body" style={{ width: "120px" }}>
                  {value.application}
                </td>
                <td className="cmath_Cell_Body" style={{ width: "120px" }}>
                  {value.completion}
                </td>
              </tr>
            );
          })}

          <tr
            style={{
              display: "flex",
              width: "max-content",
              height: "50px",
              backgroundColor: "#CAD5DB",
            }}
          >
            <td className="cmath_Cell_Body" style={{ width: "120px" }}>
              합계
            </td>
            <td className="cmath_Cell_Body" style={{ width: "120px" }}>
              {counting("access")}
            </td>
            <td className="cmath_Cell_Body" style={{ width: "120px" }}>
              {counting("content")}
            </td>
            <td className="cmath_Cell_Body" style={{ width: "120px" }}>
              {counting("application")}
            </td>
            <td className="cmath_Cell_Body" style={{ width: "120px" }}>
              {counting("completion")}
            </td>
          </tr>
        </tbody>
        <tfoot>
          <tr>
            {/* <td
              rowSpan={5}
              onClick={() =>
                (document.getElementById("count_Div").style.display = "none")
              }
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                width: "100%",
                height: "30px",
                border: "1px solid",
                marginTop: "10px",
              }}
            >
              {" "}
              닫기
            </td> */}
          </tr>
        </tfoot>
      </table>
    );
  }

  function academy_Div() {
    return (
      <div>
        <div
          style={{
            display: "flex",
            width: "max-content",
            height: "50px",
            backgroundColor: "#627A86",
          }}
        >
          <div className="cmath_Cell_Head" style={{ width: "50px" }}>
            코드
          </div>
          <div className="cmath_Cell_Head" style={{ width: "120px" }}>
            학원명
          </div>
          <div className="cmath_Cell_Head" style={{ width: "120px" }}>
            x좌표
          </div>
          <div className="cmath_Cell_Head" style={{ width: "120px" }}>
            y좌표
          </div>
          <div className="cmath_Cell_Head" style={{ width: "120px" }}>
            연락처
          </div>
          <div className="cmath_Cell_Head" style={{ width: "200px" }}>
            이메일
          </div>
          <div className="cmath_Cell_Head" style={{ width: "70px" }}>
            지역
          </div>
          <div className="cmath_Cell_Head" style={{ width: "150px" }}>
            주소
          </div>
          <div className="cmath_Cell_Head" style={{ width: "150px" }}>
            무료체험수업
          </div>
          <div className="cmath_Cell_Head" style={{ width: "150px" }}>
            시매쓰데이
          </div>
          <div className="cmath_Cell_Head" style={{ width: "100px" }}>
            현황
          </div>
        </div>
        {academy_List.map((value, index) => {
          return (
            <div
              key={index}
              style={{
                display: "flex",
                width: "max-content",
                height: "100px",
                backgroundColor: index % 2 === 0 ? "#fff" : "#E5E5E5",
              }}
            >
              <div className="cmath_Cell_Body" style={{ width: "050px" }}>
                {value.academy_Code}
              </div>
              <div className="cmath_Cell_Body" style={{ width: "120px" }}>
                {value.academy_Name}
              </div>
              <div className="cmath_Cell_Body" style={{ width: "120px" }}>
                {value.academy_X}
              </div>
              <div className="cmath_Cell_Body" style={{ width: "120px" }}>
                {value.academy_Y}
              </div>
              <div className="cmath_Cell_Body" style={{ width: "120px" }}>
                {value.academy_Phone}
              </div>
              <div className="cmath_Cell_Body" style={{ width: "200px" }}>
                {value.academy_Mail}
              </div>
              <div className="cmath_Cell_Body" style={{ width: "070px" }}>
                {value.academy_Region}
              </div>
              <div className="cmath_Cell_Body" style={{ width: "150px" }}>
                {value.academy_Address}
              </div>
              <div className="cmath_Cell_Body" style={{ width: "150px" }}>
                <div
                  className="grade_Color_Div"
                  onClick={() =>
                    usage(value._id, value.experience_use === "0" ? "1" : "0", 'experience')
                  }
                >
                  <div
                    className="grade_Color_Back"
                    style={{
                      cursor: "pointer",
                      backgroundColor: value.experience_use === "1" ? "red" : null,
                      border: value.experience_use === "1" ? "1px solid red" : null,
                    }}
                  ></div>
                  <div
                    className="grade_Color_Toggle"
                    style={{
                      cursor: "pointer",
                      left: value.experience_use === "1" ? "68%" : null,
                    }}
                  ></div>
                  <div
                    className="grade_Color_Text"
                    style={{
                      cursor: "pointer",
                      left: value.experience_use === "1" ? "10%" : null,
                    }}
                  >
                    사용
                  </div>
                </div>
              </div>
              <div className="cmath_Cell_Body" style={{ width: "150px" }}>
                <div
                  className="grade_Color_Div"
                  onClick={() =>
                    usage(value._id, value.cmathday_use === "0" ? "1" : "0", 'cmathday')
                  }
                >
                  <div
                    className="grade_Color_Back"
                    style={{
                      cursor: "pointer",
                      backgroundColor: value.cmathday_use === "1" ? "red" : null,
                      border: value.cmathday_use === "1" ? "1px solid red" : null,
                    }}
                  ></div>
                  <div
                    className="grade_Color_Toggle"
                    style={{
                      cursor: "pointer",
                      left: value.cmathday_use === "1" ? "68%" : null,
                    }}
                  ></div>
                  <div
                    className="grade_Color_Text"
                    style={{
                      cursor: "pointer",
                      left: value.cmathday_use === "1" ? "10%" : null,
                    }}
                  >
                    사용
                  </div>
                </div>
              </div>
              <div
                className="cmath_Cell_Body"
                style={{
                  width: "100px",
                  cursor: "pointer",
                  height: "30px",
                  overflow: "visible",
                  padding: "0",
                  background: "#fff",
                  borderRadius: "100px",
                  border: "1px solid #ccc",
                  marginTop: "35px",
                }}
                onClick={() => history.push("/academy", value.academy_Name)}
              >
                보러가기
              </div>
            </div>
          );
        })}
      </div>
    );
  }

  return (
    <div
      id="master_View"
      style={{
        display: "none",
        paddingTop: "60px",
        width: "max-content",
        margin: "0 auto",
      }}
    >
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          marginBottom: "22px",
        }}
      >
        <img src={logo} alt="" style={{ width: "66px" }} />
      </div>
      <div
        style={{
          cursor: "pointer",
          display: "flex",
          justifyContent: "flex-end",
          marginBottom: "100px",
        }}
        onClick={() =>
          (document.getElementById("count_Div").style.display = "block")
        }
      >
        <img src={statistics} alt="통계" />
      </div>
      {/* <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          width: "100%",
          height: "30px",
          border: "1px solid",
        }}
        onClick={() =>
          (document.getElementById("count_Div").style.display = "block")
        }
      >
        통계
      </div> */}

      {count_Div()}
      {academy_Div()}
    </div>
  );
}

export default Cmath;
