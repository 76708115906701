import Axios from "axios";
import React, { useState } from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Select from "react-select";
import swal from "sweetalert2";
import { compareCode, removeCode } from "../../../../_action/user_action";
import * as set from "../../Cmath/counting";
import phoneArray from "../phone";
import "./CheckInput.css";

function CheckInput({ setState, setStatus }) {
  const [phone, setPhone] = useState("");
  const [phone1, setPhone1] = useState({ value: "010", label: "010" });
  const [phone2, setPhone2] = useState("");
  const [phone3, setPhone3] = useState("");
  const [phoneErr, setPhoneErr] = useState("");

  // 인증번호 입력창 등장
  const [showAuth, setShowAuth] = useState(false);

  // 입력한 코드값
  const [code, setCode] = useState("");

  const [codeErr, setCodeErr] = useState("");

  // 타이머
  const [timeLimit, setTimeLimit] = useState(0);

  const dispatch = useDispatch();

  const type = useSelector((state) => state.user.registerType);

  useEffect(() => {
    if (timeLimit > 0) {
      const countdown = setInterval(() => {
        setTimeLimit(timeLimit - 1);
      }, 1000);
      return () => {
        clearInterval(countdown);
      };
    }
    setShowAuth(false);
  }, [timeLimit]);

  async function smsAuth() {
    let phoneNum = `${phone1.value}${phone2}${phone3}`;
    setPhoneErr("");
    setCodeErr("");
    setCode("");
    if (phoneNum.length < 10) {
      swal.fire("전화번호를 정확히 입력해주세요.");
      setTimeLimit(0);
      setShowAuth(false);
      return;
    }
    let checkPhone = await Axios.post("/api/user/check_phone", {
        phone: phoneNum,
        registerType: type
    });
    if (!checkPhone.data.check) {
      swal.fire("등록되지 않은 번호입니다.");
      setTimeLimit(0);
      setShowAuth(false);
      return;
    }
    if (timeLimit > 170) {
      swal.fire("잠시 후에 다시 시도해 주세요.");
      return;
    }
    Axios.post("/api/user/make_code", {
      phone: phoneNum,
    }).then((res) => {
      if (res.data.success) {
        setTimeLimit(180);
        setShowAuth(true);
        setPhone(phoneNum);
        // console.log(res.data.code);
        swal.fire("인증번호가 발송되었습니다.");
      } else {
        swal.fire("인증번호 발송에 실패하였습니다.");
      }
    });
  }

  function number_Check(e) {
    const rule = /[^0-9]/g;
    if (rule.exec(e.target.value) !== null) {
      e.target.value = e.target.value.replace(rule, "");
      setPhoneErr("숫자만 입력해주세요.");
    } else {
      setPhoneErr("");
    }
  }

  function confirmAuth() {
    if (code.length < 6) {
      setCodeErr("코드를 정확히 입력해주세요.");
      return;
    }
    const data = { phone: phone, code: code };
    dispatch(compareCode(data)).then((v) => {
      let success = v.payload.success;
      if (success) {
        setTimeLimit(0);
        swal
          .fire({
            title: "인증되었습니다.",
            timer: 2000,
          })
          .then(() => {
            setCodeErr("");
            setCode("");
            dispatch(removeCode({ phone: phone }));
            findValue();
          });
      } else setCodeErr("코드를 확인해주세요.");
    });
  }

  function findValue() {
    Axios.post("/api/user/find", { phone: phone, registerType: type }).then((res) => {
      if (res.data.success) {
        set.counting("find");
        setStatus({
          user: res.data.user,
          class: res.data.class,
          status: res.data.status,
        });
        setState(2);
      } else {
        swal.fire("결과를 불러오는데 문제가 발생하였습니다.");
      }
    });
  }

  const phone1Style = {
    container: (provided) => ({
      ...provided,
      width: "50%",
      height: "100%",
      justifyContent: "center",
      alignItems: "center",
    }),
    control: (provided) => ({
      ...provided,
      height: "100%",
      border: 0,
      outline: 0,
    }),
    valueContainer: (provided) => ({
      ...provided,
      justifyContent: "center",
    }),
    indicatorSeparator: (defaultStyles) => ({
      ...defaultStyles,
      display: "none",
    }),
    indicatorsContainer: (defaultStyles) => ({
      ...defaultStyles,
      display: "none",
    }),
    menu: (defaultStyles) => ({
      ...defaultStyles,
    }),
    dropdownIndicator: (defaultStyles) => ({
      ...defaultStyles,
      "& svg": { display: "none" },
      "&::before": {
        border: "…",
        transform: "rotate(…)",
      },
      "&::after": {
        border: "…",
        transform: "rotate(…)",
      },
    }),
  };

  return (
    <>
      <div
        style={{
          display: "flex",
          background: type === "experience" ? 'rgb(51, 156, 193)' : 'rgb(71, 173, 135)',
          alignItems: "center",
          justifyContent: "space-around",
          height: "7vh",
        }}
      >
        <span className="wait">신청조회</span>
      </div>

      <div
        className="wrap"
        style={{
          display: "flex",
          flexDirection: "column",
          marginTop: "10%",
        }}
      >
        <h4>휴대폰 인증 후에 신청 정보를 조회할 수 있습니다.</h4>
        <div className="inputwrapdiv">
          <Select
            styles={phone1Style}
            isSearchable={false}
            options={phoneArray}
            value={phone1}
            placeholder=""
            onChange={(v) => setPhone1(v)}
          />

          {"    - "}
          <input
            type="text"
            value={phone2}
            style={{
              width: "59%",
              border: "none",
              marginBottom: "0",
              height: "100%",
              textAlign: "center",
              padding: 0,
            }}
            maxLength={4}
            onInput={(e) => number_Check(e, "phone")}
            onChange={(e) => setPhone2(e.target.value)}
          />
          {" - "}
          <input
            type="text"
            value={phone3}
            style={{
              display: "flex",
              width: "59%",
              border: "none",
              marginBottom: "0",
              height: "100%",
              textAlign: "center",
              padding: 0,
            }}
            maxLength={4}
            onInput={(e) => number_Check(e, "phone")}
            onChange={(e) => setPhone3(e.target.value)}
          />
          <button
            onClick={() => smsAuth()}
            style={{
              width: "200px",
              height: "32px",
              lineHeight: "32px",
              textAlign: "center",
              borderRadius: "100px",
              background: "#C0C0C0",
              letterSpacing: "-0.3px",
              border: "none",
              fontSize: "15px",
              color: "#fff",
            }}
          >
            인증받기
          </button>
        </div>
        <div
          style={{
            marginTop: "-10px",
            color: "red",
            textAlign: "left",
            marginBottom: "10px",
            fontSize: "12px",
            height: "14px",
          }}
        >
          {phoneErr}
        </div>
        {/* </div> */}
        {showAuth && (
          <>
            <div>
              <div className="inputwrapdiv">
                <input
                  type="text"
                  style={{
                    width: "75%",
                    border: "none",
                    height: "100%",
                    marginBottom: "0",
                  }}
                  maxLength={6}
                  value={code}
                  placeholder="인증번호 입력"
                  onInput={(e) => number_Check(e, "code")}
                  onChange={(e) => setCode(e.target.value)}
                />
                <div
                  style={{
                    width: "25%",
                    float: "right",
                    lineHeight: "48px",
                    color: "#E60012",
                  }}
                >
                  {parseInt(timeLimit / 60)} :{" "}
                  {timeLimit % 60 >= 10
                    ? timeLimit % 60
                    : "0" + (timeLimit % 60)}
                </div>
              </div>
              <div
                style={{
                  height: "14px",
                  marginTop: "-10px",
                  color: "red",
                  fontSize: "12px",
                  textAlign: "left",
                }}
              >
                {codeErr}
              </div>
              <button
                className="prevB"
                style={{
                  width: "100%",
                  marginTop: "10px",
                  marginBottom: "10px",
                  fontWeight: "400",
                }}
                onClick={() => confirmAuth()}
              >
                인증완료
              </button>
            </div>
          </>
        )}
      </div>
    </>
  );
}

export default CheckInput;
