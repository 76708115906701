import React from "react";
import * as set from "../Cmath/counting";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import main1_button1 from "./image/main1_button1.png"
import main1_button2 from "./image/main1_button2.png"

import main2_button1 from "./image/main2_button1.png"
import main2_button2 from "./image/main2_button2.png"

function First() {
	const history = useHistory();
	const type = useSelector((state) => state.user.registerType);

	return (
		<div
            className={`main ${type === 'experience' ? 'main1' : 'main2'}`}
        >
			<div
                style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    width: "100%"
                }}
            >
                {type === "experience" &&
                <>
                    <div
                        style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center"
                        }}
                        onClick={() => {
							set.counting("application");
							history.push(`/${type}/select`);
						}}
                    >
                        <img src={main1_button1} style={{width: "70%"}}></img>
                    </div>
                    <div
                        style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center"
                        }}
                        onClick={() => history.push(`/${type}/check`)}
                    >
                        <img src={main1_button2} style={{width: "70%"}}></img>
                    </div>
                </>
                }
                {type === "cmathday" &&
                <>
                    <div
                        style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center"
                        }}
                        onClick={() => {
							set.counting("application");
							history.push(`/${type}/select`);
						}}
                    >
                        <img src={main2_button1} style={{width: "70%"}}></img>
                    </div>
                    <div
                        style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center"
                        }}
                        onClick={() => history.push(`/${type}/check`)}
                    >
                        <img src={main2_button2} style={{width: "70%"}}></img>
                    </div>
                </>
                }
					{/* <button
						onClick={() => {
							set.counting("application");
							history.push(`${type}/select`);
						}}
					>
						{type === "experience" && "수업신청"}
						{type === "cmathday" && "참가신청"}
					</button>
					<button onClick={() => history.push(`${type}/check`)}>신청조회</button> */}
				{/* <section id="section07" className="demo">
          <h1>프로그램 설명 보기</h1>
          <a href="/" onClick={(e) => e.preventDefault()}>
            <p>Scroll</p>
            <div>
              <span></span>
              <span></span>
              <span></span>
            </div>
          </a>
        </section> */}
				{/* <a href="#" onClick={(e) => e.preventDefault()}>
          시매쓰 영재통합반이란?
        </a> */}
			</div>
		</div>
	);
}

export default First;
