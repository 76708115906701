import "react-app-polyfill/ie9";
import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";

import React from "react";
import { hydrate, render } from "react-dom";
import "./index.css";
import App from "./components/App";
import * as serviceWorker from "./serviceWorker";
import { Provider } from "react-redux";
import promiseMiddleware from "redux-promise";
import ReduxThunk from "redux-thunk";
import Reducer from "./_reducers";
import { applyMiddleware, createStore } from "redux";
import { HelmetProvider } from "react-helmet-async";

const createStoreWithMiddleware = applyMiddleware(promiseMiddleware, ReduxThunk)(createStore);
const container = document.getElementById("root");

if (container.hasChildNodes()) {
	hydrate(
		<HelmetProvider>
			<Provider
				store={createStoreWithMiddleware(
					Reducer,
					window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
				)}
			>
				<App />
			</Provider>
		</HelmetProvider>,
		container
	);
} else {
	render(
		<HelmetProvider>
			<Provider
				store={createStoreWithMiddleware(
					Reducer,
					window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
				)}
			>
				<App />
			</Provider>
		</HelmetProvider>,
		container
	);
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
